var config = {
  // baseURL: "https://jc08o9dt14.execute-api.us-east-2.amazonaws.com/test/",
  baseURL: "https://klazjadjh1.execute-api.us-east-2.amazonaws.com/v0/",
  id_tokenAdmin:
    "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr.admin.idToken",
  id_tokenRegulator:
    "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr.regulator.idToken",
  adminFileProcess: "admin/shareplus/file-info",
  channelList: "admin/shareplus/channel",
  removeHash: "admin/shareplus/reset?reset_hash=",
  memberUpload: "admin/shareplus/upload-membership?channel=",
  needUpload: "admin/shareplus/upload-need?channel=",
  accumulatorUpload: "admin/shareplus/upload-accumulator?channel=",
  transactionUpload: "admin/shareplus/upload-transaction?channel=",
  reset: "admin/shareplus/reset",
  promote: "admin/shareplus/promote",
  agentRule: "admin/shareplus/agent-commision",
  deleteAgentRule: "admin/shareplus/agent-commision?rule_id=",
  regulatorPayableShareWalletBalance: "payable/sharewallet-balance?",
  regulatorPayableOpsDashbard:
    "payable/get-quicksight-dashboard?dashboard_id=",
  regulatorPayableAdminReserveWeekly:
    "payable/admin-reserve?report-type=weekly",

  regulatorPayableAdminReserveMonthly:
    "payable/admin-reserve?report-type=monthly",

  regulatorPayableYTDReport: "payable/ytd-report?",
  regulatorPayableYTDReportDaily: "payable/ytd-transaction-report?",

  regulatorPayableYTDTransReport:
    "payable/regulator-report?report-type=transaction_expansion&required_date=",

  regulatorPayableYTDReportMonthly:
    "payable/ytd-report?report-type=monthly&channel=",

  regulatorPayableYTDReportWeekly:
    "payable/ytd-report?report-type=weekly&channel=",

  regulatorPayableTransactionRule: "admin/shareplus/channel-rule?channel=",
  regulatorPayableAggregateReportByChannel: "payable/aggregate-bucket",
  regulatorPayableShareplusSummary: "payable/shareplus-meta-info",

  regulatorMonthlyNeedsAggregate: "needs-and-contribution/monthly-needs",
  regulatorRawData: "needs-and-contribution/raw-data",

  regulatorNeeds: "needs-and-contribution?report-type=needs_report&",
  regulatorNeedExport: "/needs-and-contribution?report-type=needs_list",
  regulatorNeedsRequests: "needs-and-contribution/need-requests?",
  regulatorNeedsContributionDetails:
    "regulator-report?report-type=contribution_details&claim_id=",

  regulatorHouseHoldReport:
    "subscriber/household-report?report-type=search_household&",
  regulatorHouseHoldQuery:
    "subcriber/household-report?report-type=query_household&",
  regulatorHouseHoldQueryTransaction: "subcriber/transactions?empi=",
  regulatorHouseHoldQueryNeeds: "subcriber/needs?empi=",
  regulatorHouseHoldQueryContribution: "subcriber/contribution?empi=",

  regulatorHouseHoldMonthlyReport:
    "subcriber/household-report?report-type=household_monthly_report",
  regulatorHouseHoldWeeklyReport:
    "subcriber/household-report?report-type=household_weekly_report_detail",
  regulatorMemberMonthlyReport:
    "subcriber/household-report?report-type=member_monthly_report",
  regulatorMemberWeeklyReport:
    "subcriber/household-report?report-type=member_weekly_report_detail",
  regulatorPastDue: "subscriber/household-past-due?",
  regulatorSaleByPlan: "subcriber/household-report?report-type=sales_by_plan",
  regulatorAgencyCommission: "agency/commissions",
  regulatorAgentCommission: "agent/commissions",
  regulatorAgenctTransaction: "agent/transactions?agent_id=",
  regulatorAgentSales: "agent/sales?report-type=agent_sales",
  regulatorAgentSalesDetailed:
    "agent/sales?report-type=agent_sales_detail&agent_id=",
  empDetails: "/admin/shareplus/member-info?uuid=",

  regulatorAgencyCommissionReport: "commissions/weekly-reports",
  // regulatorAgencyCommissionReportFilter: "agency/commission-reports/filter?",
  // regulatorAgencyCommissionPostReport: "agency/commission-reports?folder="
};

export default config;
