import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import MaterialTable ,{ MTableToolbar }from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import { setNeedsRequest } from '../../../../redux/actions/productActions';
import { ExportToCsv } from "export-to-csv";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from 'moment';
import buttonSymbol from "../../../../assets/images/calender.png"

const NeedsRequests = () => {
  const tableRef = React.createRef();
  const now = new Date();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now
    .getDate()
    .toString()
    .padStart(2, "0");
  const tempToDate = `${now.getFullYear()}-${month}-${day}`;
  const [toDateCurrent, setToDateCurrent] = useState(tempToDate);

  const [load, setLoad] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [agentListCall, setAgentListCall] = useState(true);
  const [downloadLoad, setDownloadLoad] = useState(false);
  const [fromDate, setFromDate] = useState("2011-06-09");
  const [toDate, setToDate] = useState(tempToDate);

    let username = localStorage.getItem(
      "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr.LastAuthUser"
    );
    let id_token = localStorage.getItem(
      "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr." +
        username +
        ".idToken"
    );
  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "NeedsRequest"
  };
  const csvExporter = new ExportToCsv(options);
  async function fetchList(fromDate, toDate) {
    setFromDate(fromDate)
    setToDate(toDate)
    setLoad(true);
    const request = await axios.get(
      config.regulatorNeedsRequests +
      "from_date=" +
      fromDate +
      "&to_date=" +
      toDate,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
 
    dispatch(setNeedsRequest(request.data[0].payload));
    setAgentList(request.data[0].payload);
    setLoad(false);
  }
  async function downloadList() {
  setDownloadLoad(true);
    // const request = await axios.get(
    //   config.regulatorNeedsRequests +
    //   "from_date=" +
    //   fromDate +
    //   "&to_date=" +
    //   toDate,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "x-api-key": apikey.xapikey,
    //       token: id_token
    //     }
    //   }
    // );

    let tempNeedData = [];
    const tempData = agentList;
        // const tempData = request.data[0].payload;

    for (let i = 0; i < tempData.length; i++) {
      tempNeedData.push({
        "Claim Status": tempData[i].claim_status,
        "Needs Requests": tempData[i].needs_count,
        "% of Total Needs": tempData[i].needs_percentage,
        "Original Charge": tempData[i].charged_amount,
        "Repricing Discount": tempData[i].repricing_amount,
        "Ineligible Amount": tempData[i].ineligible_amount,
        "Applied to MCP": tempData[i].mcp,
        "Applied to Consultation Fee": tempData[i].consultation_fee,
        "Applied to Sharing Percentage": tempData[i].sharing_percentage,
        "Paid": tempData[i].paid_amount 
      });
    }
  
    csvExporter.generateCsv(tempNeedData);
    setDownloadLoad(false);
  }
  useEffect(() => {
    if (agentListCall === true) {
      setAgentListCall(false);
      if (dataList.channels.needsRequest.length > 0 && (agentList[0] === 'null' || agentList[0] === undefined)) { 
        setAgentList(dataList.channels.needsRequest);
      } else {

        setLoad(true);

        fetchList(fromDate, toDate);
        setAgentListCall(false);
      }
      
    }
  }, [
      agentListCall,
      agentList
    ]);
  

  // const GetTotalPercentage = (cell) => {
  //   return (
  //     <div>
  //       {cell.needs_percentage}%
  //     </div>
  //   );
  // };

  // const GetChargedAmount = (cell) => {
  //   var amount = cell.charged_amount.toLocaleString("en-US", {
  //     style: "currency",
  //     currency: "USD",
  //   })
  //   return (
  //     <div>
  //       {amount}
  //     </div>
  //   );
  // };

  // const GetRepricingAmount = (cell) => {
  //   var amount = cell.repricing_amount.toLocaleString("en-US", {
  //     style: "currency",
  //     currency: "USD",
  //   })
  //   return (
  //     <div>
  //       {amount}
  //     </div>
  //   );
  // };

  // const GetIneligibleAmount = (cell) => {
  //   var amount = cell.ineligible_amount.toLocaleString("en-US", {
  //     style: "currency",
  //     currency: "USD",
  //   })
  //   return (
  //     <div>
  //       {amount}
  //     </div>
  //   );
  // };

  // const GetNsa = (cell) => {
  //   var amount = cell.nsa.toLocaleString("en-US", {
  //     style: "currency",
  //     currency: "USD",
  //   })
  //   return (
  //     <div>
  //       {amount}
  //     </div>
  //   );
  // };

  // const GetConsultationFee = (cell) => {
  //   var amount = cell.consultation_fee.toLocaleString("en-US", {
  //     style: "currency",
  //     currency: "USD",
  //   })
  //   return (
  //     <div>
  //       {amount}
  //     </div>
  //   );
  // };

  // const GetSharingPercentage = (cell) => {
  //   var amount = cell.sharing_percentage.toLocaleString("en-US", {
  //     style: "currency",
  //     currency: "USD",
  //   })
  //   return (
  //     <div>
  //       {amount}
  //     </div>
  //   );
  // };

  // const GetPaidAmount = (cell) => {
  //   var amount = cell.paid_amount.toLocaleString("en-US", {
  //     style: "currency",
  //     currency: "USD",
  //   })
  //   return (
  //     <div>
  //       {amount}
  //     </div>
  //   );
  // };

  const convert = str => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleEvent = (event, picker) => {
    setFromDate(convert(picker.startDate._d));
    setToDate(convert(picker.endDate._d));
    setAgentListCall(true);
  };


  const columns = [
    {
      field: "claim_status",
      title: "Claim Status",
      cellStyle: {
        width: "300px"
      }
    },
    {
      field: "needs_count",
      title: "Needs Requests"
    },
    {
      field: "needs_percentage",
      title: "% of Total Needs"
      // render: rowData => GetTotalPercentage(rowData)
    },
    {
      field: "charged_amount",
      title: "Original Charge"
      // render: rowData => GetChargedAmount(rowData)
    },
    {
      field: "repricing_amount",
      title: "Repricing Discount"
      // render: rowData => GetChargedAmount(rowData)
    },
    {
      field: "ineligible_amount",
      title: "Ineligible Amount"
      // render: rowData => GetIneligibleAmount(rowData)
    },
    {
      field: "mcp",
      title: "Applied to MCP"
      // render: rowData => GetNsa(rowData)
    },
    {
      field: "consultation_fee",
      title: "Applied to Consultation fee"
      // render: rowData => GetConsultationFee(rowData)
    },
    {
      field: "sharing_percentage",
      title: "Applied to Sharing Percentage"
      // render: rowData => GetSharingPercentage(rowData)
    },
    {
      field: "paid_amount",
      title: "Paid"
      // render: rowData => GetPaidAmount(rowData)
    }
  ];



  return (
    <ContentWrapper>
      {/* <div className="comingSoon clearfix">Coming soon...</div> */}
      <span className="pageheading"> Needs Requests</span>

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ textAlign: "center" }}>Loading List</div>
        )}

        {load === false && (
          <MaterialTable
            // other props
            components={{
              Toolbar: props => (
                <div style={{ backgroundColor: "#f6f6e6" }}>
                  <MTableToolbar {...props} />
                  {downloadLoad === true && <div className="loaderDwnld" />}
                  {downloadLoad === true && (
                    <div
                      style={{
                        textAlign: "center",
                        position: "absolute",
                        right: "50px"
                      }}
                    >
                      downloading
                    </div>
                  )}
                  <DateRangePicker
                    onApply={handleEvent}
                    parentEl={"#reportDRPContainerWallet"}
                    ranges={{
                      Today: [moment(), moment()],
                      Yesterday: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days")
                      ],
                      "Last 7 Days": [moment().subtract(6, "days"), moment()],
                      "Last 30 Days": [moment().subtract(29, "days"), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month")
                      ],
                      "Last Month": [
                        moment()
                          .subtract(1, "month")
                          .startOf("month"),
                        moment()
                          .subtract(1, "month")
                          .endOf("month")
                      ],
                      "Last Year": [moment().subtract(366, "days"), moment()],
                      "Last 5 Years": [
                        moment().subtract(1826, "days"),
                        moment()
                      ]
                    }}
                    alwaysShowCalendars={true}
                  >
                    <button className="filterByDateButton dateFilterPositionNeedsReq">
                      <img
                        alt=""
                        src={buttonSymbol}
                        className="buttonSymbol"
                      />
                      {fromDate === "2011-06-09" ? (
                        <div>
                          Filter by Date
                        </div>
                      ) : (
                        <div>
                          {fromDate} - {toDate}
                        </div>
                      )}
                    </button>
                  </DateRangePicker>
                  <div style={{ display: "inline-block" }}></div>
                </div>
              )
            }}
            columns={columns}
            data={agentList}
            options={{
              pageSize: 10,
              searchFieldAlignment: "left",
              searchFieldStyle: {
                backgroundColor: "white"
              }
            }}
            title={false}
            actions={[
              {
                icon: "save_alt",
                tooltip: "Export Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#eb5757",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                    color: "white"
                  }
                },
                onClick: () => downloadList()
              },
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#41b5c2",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                    color: "white"
                  }
                },
                onClick: () => fetchList("2011-06-09", toDateCurrent)
              }
            ]}
          />
        )}
      </div>
    </ContentWrapper>
  );
};

export default NeedsRequests;

