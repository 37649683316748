import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import { ExportToCsv } from "export-to-csv";
import { useSelector, useDispatch } from "react-redux";
import { setAggregateReportByChannel } from '../../../../redux/actions/productActions';
import MaterialTable ,{ MTableToolbar }from 'material-table';


const AggregateReportByChannel = () => {
const [load, setLoad] = useState(false);
const [agentList, setAgentList] = useState([]);
const [agentListCall, setAgentListCall] = useState(true);
  const [downloadLoad, setDownloadLoad] = useState(false);

  let username = localStorage.getItem(
    "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr.LastAuthUser"
  );
  let id_token = localStorage.getItem(
    "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr." +
      username +
      ".idToken"
  );
const dataList = useSelector((state) => state);
const dispatch = useDispatch();
const options = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  filename: "Monthly Needs Aggregate"
};
const csvExporter = new ExportToCsv(options);
async function fetchList() {
  setLoad(true);
  const request = await axios.get(
      config.regulatorPayableAggregateReportByChannel,
      {
          headers: {
              "Content-Type": "application/json",
              "x-api-key": apikey.xapikey,
              token: id_token
          }
      }
  );
  dispatch(setAggregateReportByChannel(request.data));
  setAgentList(request.data);
  setLoad(false);
}
async function downloadList() {
    setDownloadLoad(true);

  // const request = await axios.get(
  //   config.regulatorPayableAggregateReportByChannel,
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       "x-api-key": apikey.xapikey,
  //       token: id_token
  //     }
  //   }
  // );
  let aggregateData = [];
  const tempData = agentList;
      // const tempData = request.data[0].payload;

  for (let i = 0; i < tempData.length; i++) {
    aggregateData.push({
      "Channel Name": tempData[i].channel_name,
      "Sharewallet Balance": tempData[i].sharewallet_balance,
      "Additional Revenue": tempData[i].additional_revenue,
      "Administration Expenses": tempData[i].administration_expenses,
      "BMA Service Fees": tempData[i].bma_service_fees,
      "Commission Load": tempData[i].commission_load,
      "Monthly Share": tempData[i].monthly_share,
      "Fixed Expenses": tempData[i].fixed_expenses,
      "Membership Fee": tempData[i].membership_fee,
      "Application Fee": tempData[i].application_fee,
      "CC Fee": tempData[i].cc_fee
    });
  }

  csvExporter.generateCsv(aggregateData);
    setDownloadLoad(false);
}
  useEffect(() => {

    setAgentList(dataList.channels.aggregateReportByChannel);

    if (dataList.channels.aggregateReportByChannel.length === 0) {
          setLoad(true);
          fetchList();
      }
  }, []);


    const columns = [
      {
        field: "channel_name",
        title: "Channel Name"
      },
      {
        field: "sharewallet_balance",
        title: "Sharewallet Balance",
        headerStyle: { minWidth: "200px" }
      },
      {
        field: "additional_revenue",
        title: "Additional Revenue	" 
      },
      {
        field: "administration_expenses",
        title: "Administration Expenses" 
      },
      {
        field: "bma_service_fees",
        title: "BMA Service Fees" 
      },
      {
        field: "commission_load",
        title: "Commission Load"
      } 
    ];



    return (
      <ContentWrapper>
        <span className="pageheading">Aggregate Report By Channel </span>
        {/* <div className="comingSoon clearfix">Coming soon...</div> */}
        <div className="tableBody clearfix">
          {load === true && <div className="loader" />}
          {load === true && (
            <div style={{ textAlign: "center" }}>Loading List</div>
          )}

          {load === false && agentList.length === 0 && <div>NO data found</div>}
          {load === false && agentList.length > 0 && (
            <MaterialTable
              // other props
              components={{
                Toolbar: props => (
                  <div style={{ backgroundColor: "#f6f6e6" }}>
                    <MTableToolbar {...props} />
                    {downloadLoad === true && <div className="loaderDwnld" />}
                    {downloadLoad === true && (
                      <div
                        style={{
                          textAlign: "center",
                          position: "absolute",
                          right: "50px"
                        }}
                      >
                        downloading
                      </div>
                    )}
                    <div style={{ display: "inline-block" }}></div>
                  </div>
                )
              }}
              columns={columns}
              data={agentList}
              detailPanel={rowData => {
                return (
                  <div className="detailPanel">
                      <table className="detailPanelTable">
                        <tr className="detailPaneltr">
                          <th className="detailPanelth" scope="col">
                             Monthly Share
                          </th> 
                          <th className="detailPanelth" scope="col">
                            Fixed Expenses
                          </th>
                          <th className="detailPanelth" scope="col">
                            Membership Fee
                          </th> 
                          <th className="detailPanelth" scope="col">
                            Application Fee
                          </th>
                          <th className="detailPanelth" scope="col">
                            CC Fee
                          </th> 
                        </tr>
                        <tr>
                          <td className="detailPaneltd">
                            {rowData.monthly_share}
                          </td> 
                          <td className="detailPaneltd">
                            {rowData.fixed_expenses}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.membership_fee}
                          </td> 
                          <td className="detailPaneltd">
                            {rowData.application_fee}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.cc_fee}
                          </td> 
                        </tr>
                      </table>
                      
                    </div>
                  
                );
              }}
              options={{
                pageSize: 10,
                searchFieldAlignment: "left",
                searchFieldStyle: {
                  backgroundColor: "white"
                }
              }}
              title={false}
              actions={[
                {
                  icon: "save_alt",
                  tooltip: "Export Data",
                  isFreeAction: true,
                  iconProps: {
                    style: {
                      backgroundColor: "#eb5757",
                      width: "35px",
                      height: "35px",
                      borderRadius: "20px",
                      paddingTop: "6px",
                      color: "white"
                    }
                  },
                  onClick: () => downloadList()
                },
                {
                  icon: "refresh",
                  tooltip: "Refresh Data",
                  isFreeAction: true,
                  iconProps: {
                    style: {
                      backgroundColor: "#41b5c2",
                      width: "35px",
                      height: "35px",
                      borderRadius: "20px",
                      paddingTop: "6px",
                      color: "white"
                    }
                  },
                  onClick: () => fetchList()
                }
              ]}
            />
          )}
        </div>
      </ContentWrapper>
    );
};

export default AggregateReportByChannel;

