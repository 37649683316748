import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useSelector, useDispatch } from "react-redux";
import { setAgentCommission } from '../../../../redux/actions/productActions';
import MaterialTable ,{ MTableToolbar }from 'material-table';
import { ExportToCsv } from "export-to-csv";
import close from "../../../../assets/images/close.png";

const AgentCommission = () => {


  const [load, setLoad] = useState(false);
  const [transactionLoad, setTransactionLoad] = useState(false);

  const [agentList, setAgentList] = useState([]);
  const [transactionList, setTransactionList] = useState([]);



  const [agentListCall, setAgentListCall] = useState(true);
  const [agentId, setAgentId] = useState(true);

  const [empkey, setEmpkey] = useState(true);

  const [transactionClick, setTransactionClick] = useState(false);

   const [anchorEl, setAnchorEl] = React.useState(null);
   const [anchorElExport, setAnchorElExport] = React.useState(null);
     const [downloadLoad, setDownloadLoad] = useState(false);

   const open = Boolean(anchorEl);
   const openExport = Boolean(anchorElExport);
    let username = localStorage.getItem(
      "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr.LastAuthUser"
    );
    let id_token = localStorage.getItem(
      "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr." +
        username +
        ".idToken"
    );
  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();

  
  const pageNo = 1
  const sizePerPage = 10

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "Agent Commission"
  };
  const csvExporter = new ExportToCsv(options);
  const  fetchTransaction=async ()=>{
    const request = await axios.get(
      config.regulatorAgenctTransaction + agentId, 
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    setTransactionLoad(false);
    setTransactionList(request.data[0].payload);
    const response={data:request.data[0].payload,total_count: request.data[0].meta_info[0].total_count}
    return response
  }
  async function fetchList() {
    setLoad(true);
    const request = await axios.get(
      config.regulatorAgentCommission,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    if (dataList.channels.agentCommission.length === 0) {
      dispatch(setAgentCommission(request.data));
    }
    setAgentList(request.data);
    setLoad(false);
  }
  async function downloadList() {
    setDownloadLoad(true);
    // const request = await axios.get(
    //   config.regulatorAgentCommission,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "x-api-key": apikey.xapikey,
    //       token: id_token
    //     }
    //   }
    // );

    let agentData = [];
    const tempData = agentList;
   
    for (let i = 0; i < tempData.length; i++) {
      agentData.push({
        "Agent Id": tempData[i].agent_id,
        "Agent Firstname": tempData[i].agent_firstname,
        "Agent Lastname": tempData[i].agent_lastname,
        "Channel ID": tempData[i].affiliation_id,
        "Contract type": tempData[i].association_id, 
        "Total Commission": tempData[i].total_commission
      });
    }
  
    csvExporter.generateCsv(agentData);
    setDownloadLoad(false);
  }

  async function downloadTransactionList() {
    // const request = await axios.get(
    //   config.regulatorAgenctTransaction + agentId, 
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "x-api-key": apikey.xapikey,
    //       token: id_token
    //     }
    //   }
    // );
    // console.log(request.data[0].payload)

    let agentData = [];
    const tempData = transactionList;
   
    for (let i = 0; i < tempData.length; i++) {
      agentData.push({
        "Date": tempData[i].transaction_date,
        "Name": tempData[i].first_name + tempData[i].last_name,
        "Transaction ID": tempData[i].source_transaction_id,
        "Member ID": tempData[i].member_id,
        "Affiliation ID": tempData[i].affiliation_id,
        "Channel Name": tempData[i].channel_name,
        "Plan ID": tempData[i].plan_id,
        "Association ID": tempData[i].association_id,
        "Program Name": tempData[i].program_name,
        "Payment Number": tempData[i].payment_number,
        "Payment Method": tempData[i].payment_method,
        "Agent Commission": tempData[i].commission_amount,
        "Transaction Amount": tempData[i].transaction_amount,
        "Application Fee": tempData[i].application_fee,
        "Monthly Share": tempData[i].monthly_share,
        "Membership Fee": tempData[i].membership_fee,
        "CC Fee": tempData[i].cc_fee,
        "Authorization Message": tempData[i].authorization_message,
        "Status Updated Date": tempData[i].status_updated_date 
      });
    }
  
    csvExporter.generateCsv(agentData);
  }

  
  useEffect(() => {
    if (agentListCall === true) {
      setAgentListCall(false);

      if (dataList.channels.agentCommission.length > 0 && agentList[0] === 'null') {
        setAgentList(dataList.channels.agentCommission);
      } else {
        setLoad(true);
        setAgentListCall(false);

        fetchList();
      }
    }

    if (transactionClick === true) {
      setTransactionClick(false);
      setTransactionLoad(true);

      fetchTransaction();
    }


  }, [
      agentListCall,
      agentList,
      transactionClick,
      agentId
    ]);

  const GetNameFormat = (row) => {
    return (
      <div>
        {row.first_name} {row.last_name}
      </div>
    );
  };




  const handleClick = (event, agent_id) => {
     setEmpkey(agent_id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElExport(null);   
  };

  const GetActionFormat = (cell) => {
    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={e => handleClick(e, cell.agent_id)}
        >
          <MoreVertIcon />
        </IconButton>
      </div>
    );
  };

  const columns = [
    {
      field: "agent_id",
      title: "Agent ID	"
    },
    {
      field: "agent_firstname",
      title: "First Name	"
    },
    {
      field: "agent_lastname",
      title: "Last Name	"
    },
    {
      field: "affiliation_id",
      title: "Channel ID	"
    },
    {
      field: "association_id",
      title: "Contract Type"
    },
    {
      field: "total_commission",
      title: "Total Commission	"
    },
    {
      field: "",
      title: "Action",
      render: rowData => GetActionFormat(rowData)
    }
  ];

  const transactionColumns = [
    {
      field: "transaction_date",
      title: "Date"
    },
    {
      field: "",
      title: "Name",
      render: rowData => GetNameFormat(rowData)
    },
    {
      field: "source_transaction_id",
      title: "Transaction ID	"
    },
    {
      field: "member_id",
      title: "Member ID	"
    },
    {
      field: "affiliation_id",
      title: "Affiliation ID	"
    },
    {
      field: "channel_name",
      title: "Channel Name	"
    },
    {
      field: "plan_id",
      title: "Plan ID	"
    },
    {
      field: "association_id",
      title: "Association ID	"
    },
    {
      field: "program_name",
      title: "Program Name	"
    },
    {
      field: "payment_number",
      title: "Payment Number	"
    },
    {
      field: "payment_method",
      title: "Payment Method	"
    },
    {
      field: "commission_amount",
      title: "Agent Commission"
    },
    {
      field: "transaction_amount",
      title: "Transaction Amount	"
    },
    {
      field: "application_fee",
      title: "Application Fee	"
    },
    {
      field: "monthly_share",
      title: "Monthly Share	"
    },
    {
      field: "membership_fee",
      title: "Membership Fee	"
    },
    {
      field: "cc_fee",
      title: "CC Fee	"
    },
    {
      field: "authorization_message",
      title: "Authorization Message"
    },
    {
      field: "status_updated_date",
      title: "Status Updated Date"
    }
  ];


  const transaction = (empi) => {
    setAnchorEl(null);
    setTransactionList([]);
    setTransactionClick(true);
    setAgentId(empi);
    document.getElementById("modalTransBtn").click();
  };



  return (
    <ContentWrapper>
      {/* <div className="comingSoon clearfix">Coming soon...</div> */}
      <span className="pageheading">Agent Commission</span>

      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        // onClick={handleClick}
        style={{ display: "none" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => transaction(empkey)}>
          <label>TRANSACTION</label>
        </MenuItem>
      </Menu> 

      <button
        // className={styles.addNewBtn}
        data-toggle="modal"
        id="modalTransBtn"
        data-target="#modalTransaction"
        style={{ display: "none" }}
      >
        Transaction2
      </button>

      <div
        className="modal fade"
        id="modalTransaction"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered dialogWidth">
          <div className="modal-content modal-content-bg">
            <div className="modalHeading tableModalHeading">
              Transaction Data
            </div>
            <img
              src={close}
              alt=""
              className="modalCloseBtn"
              data-dismiss="modal"
              id="modalCloseBtn"
            />

            <div className="tableModalContentDiv">
              {transactionLoad === true && <div className="loader" />}
              {transactionLoad === true && (
                <div style={{ titleAlign: "center" }}>
                  Loading Transaction Data
                </div>
              )}
              {transactionLoad === false && (
                <div>
                  {transactionList.length > 0 ? (
                    <MaterialTable
                      // other props
                      components={{
                        Toolbar: props => (
                          <div style={{ backgroundColor: "#f6f6e6" }}>
                            <MTableToolbar {...props} />
                          </div>
                        )
                      }}
                      columns={transactionColumns}
                      // data={query =>
                      //   new Promise((resolve, reject) => {
                      //     fetchTransaction(query.page + 1, query.pageSize).then(
                      //       result => {
                      //         resolve({
                      //           data: result.data, // your data array
                      //           page: query.page, // current page number
                      //           totalCount: result.total_count // total row number
                      //         });
                      //         reject();
                      //       }
                      //     );
                      //   })
                      // }
                      data={transactionList}
                      options={{
                        pageSize: 10,
                        searchFieldAlignment: "left",
                        searchFieldStyle: {
                          backgroundColor: "white"
                        }
                      }}
                      title={false}
                      actions={[
                        {
                          icon: "save_alt",
                          tooltip: "Export Data",
                          isFreeAction: true,
                          iconProps: {
                            style: {
                              backgroundColor: "#eb5757",
                              width: "35px",
                              height: "35px",
                              borderRadius: "20px",
                              paddingTop: "6px",
                              color: "white"
                            }
                          },
                          onClick: () => downloadTransactionList()
                        },
                        {
                          icon: "refresh",
                          tooltip: "Refresh Data",
                          isFreeAction: true,
                          iconProps: {
                            style: {
                              backgroundColor: "#41b5c2",
                              width: "35px",
                              height: "35px",
                              borderRadius: "20px",
                              paddingTop: "6px",
                              color: "white"
                            }
                          },
                          onClick: () => fetchTransaction(pageNo, sizePerPage)
                        }
                      ]}
                    />
                  ) : (
                    <div>No Data Found</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ titleAlign: "center" }}>Loading List</div>
        )}

        {load === false && (
          <MaterialTable
            // other props
            components={{
              Toolbar: props => (
                <div style={{ backgroundColor: "#f6f6e6" }}>
                  <MTableToolbar {...props} />
                  {downloadLoad === true && <div className="loaderDwnld" />}
                  {downloadLoad === true && (
                    <div
                      style={{
                        textAlign: "center",
                        position: "absolute",
                        right: "50px"
                      }}
                    >
                      downloading
                    </div>
                  )}
                  <div style={{ display: "inline-block" }}></div>
                </div>
              )
            }}
            columns={columns}
            data={agentList}
            options={{
              pageSize: 10,
              searchFieldAlignment: "left",
              searchFieldStyle: {
                backgroundColor: "white"
              }
            }}
            title={false}
            actions={[
              {
                icon: "save_alt",
                tooltip: "Export Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#eb5757",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                    color: "white"
                  }
                },
                onClick: () => downloadList()
              },
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#41b5c2",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                    color: "white"
                  }
                },
                onClick: () => fetchList()
              }
            ]}
          />
        )}
      </div>
    </ContentWrapper>
  );
};

export default AgentCommission;

