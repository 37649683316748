import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import { ExportToCsv } from "export-to-csv";
 import MaterialTable, { MTableToolbar } from "material-table";
import csv from "../../../../assets/images/csv.png";
import download from "../../../../assets/images/dwnld.png";

const RawData = () => {
  const [load, setLoad] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [agentListCall, setAgentListCall] = useState(true);
  const [downloadLoad, setDownloadLoad] = useState(false);

  let username = localStorage.getItem(
    "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr.LastAuthUser"
  );
  let id_token = localStorage.getItem(
    "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr." +
      username +
      ".idToken"
  );
   const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "Monthly Needs Aggregate",
  };
  const csvExporter = new ExportToCsv(options);
  async function fetchList() {
    setLoad(true);
    const request = await axios.get(config.regulatorRawData, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apikey.xapikey,
        token: id_token,
      },
    });
    setAgentList(request.data);
    setLoad(false);
  }

  async function fetchList() {
    setLoad(true);
    let tempArr = [];
    const monthNumberToLabelMap = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      "10": "Oct",
      "11": "Nov",
      "12": "Dec"
    };
    const request = await axios.get(config.regulatorRawData, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apikey.xapikey,
        token: id_token
      }
    });
    setAgentList(request.data);
    setLoad(false);
    setAgentListCall(false); 
  }

  async function downloadList() {
    setDownloadLoad(true);
    const request = await axios.get(config.regulatorRawData, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apikey.xapikey,
        token: id_token,
      },
    });

    csvExporter.generateCsv(request.data);
    setDownloadLoad(false);
  }
  useEffect(() => {
    if (agentListCall === true) {
      fetchList();
    }
  }, [agentListCall, agentList]);

  const GetActionFormat = (cell) => {
    let tempLabel = cell.file_name.slice(10,);
    return (
      <div
        className="csvMainDiv" 
      >
        <img src={csv} alt="" className="csvImg" />
        <span className="fileLabel csvMainDivFileName">{tempLabel}</span>
        <span className="fileLabel csvMainDivDate">{cell.datetime}</span>
        <a href={cell.URL}>
          <img src={download} alt="" className="csvDwnldImg" />
        </a>
      </div>
    );
  };

  const GetActionFormat1 = (cell) => {
    let tempLabel = cell.file_name.slice(10,);
    return (
          <span className="">{tempLabel}</span>
    );
  }; 
  const GetActionFormat2 = (cell) => {
    return (
        <span className="">{cell.datetime}</span>
    );
  };
  const GetActionFormat3 = (cell) => {
    return (
      <span className="">Completed</span>
    );
  };
  const GetActionFormat4 = (cell) => {
    return (
        <a href={cell.URL}>
          <img src={download} alt="" className="csvDwnldImg" />
        </a>
    );
  };

  const columns = [
    {
      field: "",
      title: "Needs Filename",
      render: (rowData) => GetActionFormat1(rowData),
    },
    {
      field: "",
      title: "Processed Date",
      render: (rowData) => GetActionFormat2(rowData),
    },
    {
      field: "",
      title: "Processing Status",
      render: (rowData) => GetActionFormat3(rowData),
    },
    {
      field: "",
      // title: "Report List",
      render: (rowData) => GetActionFormat4(rowData),
    },
  ];

  return (
    <ContentWrapper>
      {/* <div className="comingSoon clearfix">Coming soon...</div> */}
      <span className="pageheading"> Raw Data </span>

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ textAlign: "center" }}>Loading List</div>
        )}

        {load === false && (
          <MaterialTable
            // other props
            components={{
              Toolbar: (props) => (
                <div style={{ backgroundColor: "#f6f6e6" }}>
                  <MTableToolbar {...props} />
                  {downloadLoad === true && <div className="loaderDwnld" />}
                  {downloadLoad === true && (
                    <div
                      style={{
                        textAlign: "center",
                        position: "absolute",
                        right: "50px",
                      }}
                    >
                      downloading
                    </div>
                  )}
                  <div style={{ display: "inline-block" }}></div>
                </div>
              ),
            }}
            columns={columns}
            data={agentList}
            options={{
              search: false,
              pageSize: 10,
              searchFieldAlignment: "left",
              searchFieldStyle: {
                backgroundColor: "white",
              },
            }}
            title={false}
            actions={[
              // {
              //   icon: "save_alt",
              //   tooltip: "Export Data",
              //   isFreeAction: true,
              //   iconProps: {
              //     style: {
              //       backgroundColor: "#eb5757",
              //       width: "35px",
              //       height: "35px",
              //       borderRadius: "20px",
              //       paddingTop: "6px",
              //       color: "white",
              //     },
              //   },
              //   onClick: () => downloadList(),
              // },
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#41b5c2",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                    color: "white",
                  },
                },
                onClick: () => fetchList(),
              },
            ]}
          />
        )}
      </div>
    </ContentWrapper>
  );
};

export default RawData;
