import React, { useState } from "react";
import ContentWrapper from "../ContentWrapper";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import "bootstrap/dist/css/bootstrap.css";

const Settings = () => {

    const [pii, setPii] = useState({
        checked: JSON.parse(localStorage.getItem('PII'))
    });


    const handleChange = (event) => {
        setPii({ ...pii, [event.target.name]: event.target.checked });
        localStorage.setItem('PII', event.target.checked)
        localStorage.setItem('PII_CALLED', true)
        setTimeout(() => {
             localStorage.setItem('PII_CALLED', false)
        }, 10000)
    };
    return (
        <ContentWrapper>
            <span className="pageheading">  Settings</span>
            <div className="tableBody clearfix">
                <div className="clearfix">
                    <div className="pageHead">
                        < FormGroup row>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={pii.checked}
                                        onChange={handleChange}
                                        name="checked"
                                        color="primary"

                                    />
                                }
                                label="Enable Redact mode "
                                labelPlacement="start"
                                value="start"
                            />

                        </FormGroup>
                    </div>
                </div>
            </div>
        </ContentWrapper >
    );
};

export default Settings;

