import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSelector, useDispatch } from "react-redux";
import { setAgentSale } from "../../../../redux/actions/productActions";
import { ExportToCsv } from "export-to-csv";
import folder from "../../../../assets/images/folder.png";
import close from "../../../../assets/images/close.png";
import csv from "../../../../assets/images/csv.png";
import download from "../../../../assets/images/dwnld.png";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import buttonSymbol from "../../../../assets/images/calender.png";
import published from "../../../../assets/images/published.svg";
import unpublished from "../../../../assets/images/unpublished.svg";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const CommissionReport = () => {
  const now = new Date();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const tempToDate = `${now.getFullYear()}-${month}-${day}`;
  const [load, setLoad] = useState(false);
  const [transLoad, setTransLoad] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [agentListCSV, setAgentListCSV] = useState([]);
  const [agentListCall, setAgentListCall] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDateValue, setFromDateValue] = useState("");
  const [toDateValue, setToDateValue] = useState("");
  const [showCalendarFrom, setCalendarFrom] = useState(false);
  const [showCalendarTo, setCalendarTo] = useState(false);
  const [publishYearWeek, setPublishYearWeek] = useState(false);
  const [publishDisplayName, setPublishDisplayName] = useState("");

  let username = localStorage.getItem(
    "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr.LastAuthUser"
  );
  let id_token = localStorage.getItem(
    "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr." +
      username +
      ".idToken"
  );

  async function fetchList() {
    setLoad(true);
    let tempArr = [];
    const monthNumberToLabelMap = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const request = await axios.get(config.regulatorAgencyCommissionReport, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apikey.xapikey,
        token: id_token,
      },
    });

    let data = request.data[0].payload;
    // for (let i = 0; i < data.length; i++) {
    //   tempArr.push({
    //     date: data[i],
    //     dateLabel:
    //       monthNumberToLabelMap[data[i].slice(5, 7)] + " " +
    //       data[i].slice(8, 10) + ", " +
    //       data[i].slice(0, 4) +
    //         " - " +
    //       monthNumberToLabelMap[data[i].slice(16, 18)] + " " +
    //       data[i].slice(19, 21) + ", " +
    //       data[i].slice(11, 15)
    //   });
    // }
    setTimeout(() => {
      setAgentList(data);
      setLoad(false);
    }, 500);
    setAgentListCall(false);
  }

  async function fectListFilter(from, to) {
    setLoad(true);
    let tempArr = [];
    const monthNumberToLabelMap = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const request = await axios.get(
      config.regulatorAgencyCommissionReport +
        "?from_date=" +
        from +
        "&to_date=" +
        to,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token,
        },
      }
    );

    let data = request.data[0].payload;
    // for (let i = 0; i < data.length; i++) {
    //   tempArr.push({
    //     date: data[i],
    //     dateLabel:
    //       monthNumberToLabelMap[data[i].slice(5, 7)] +
    //       " " +
    //       data[i].slice(8, 10) +
    //       ", " +
    //       data[i].slice(0, 4) +
    //       " - " +
    //       monthNumberToLabelMap[data[i].slice(16, 18)] +
    //       " " +
    //       data[i].slice(19, 21) +
    //       ", " +
    //       data[i].slice(11, 15)
    //   });
    // }
    setTimeout(() => {
      setAgentList(data);
      setLoad(false);
    }, 500);
    setAgentListCall(false);
  }

  async function getTransactionReport(date) {
    setTransLoad(true);
    setAgentListCSV([]);
    let tempArr = [];
    const request = await axios.get(
      config.regulatorAgencyCommissionReport + "?folder=" + date,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token,
        },
      }
    );
    let data = request.data[0].payload;
    for (let i = 0; i < data.length; i++) {
      if (data[i].file_name.endsWith(".csv")) {
        tempArr.push({
          file_name: data[i].file_name.slice(22),
          url: data[i].url,
        });
      }
    }
    setTimeout(() => {
      setAgentListCSV(tempArr);
      setTransLoad(false);
    }, 500);
    // let x =
    //   request.data.payload[0].payload[
    //     "2022-06-12_2022-06-18/202224_2002_2022-06-12_2022-06-18.csv"
    //   ];
  }

  async function publishYearWeekSelection(display_name, year_week) {
    setPublishDisplayName(display_name);
    setPublishYearWeek(year_week);
  }

  async function publishYearWeekClick(date) {
    const request = await axios.post(
      config.regulatorAgencyCommissionReport + "?year_week=" + date,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token,
        },
      }
    );

    fetchList();

    // let data = request.data[0].payload;
    // setTimeout(() => {
    //   setAgentListCSV(data);
    //   setTransLoad(false);
    // }, 500);
  }

  useEffect(() => {
    if (agentListCall === true) {
      fetchList();
    }
  }, [agentListCall, agentList]);

  const GetActionFormat = (cell) => {
    return (
      <div
        className="dateLabelDiv"
        onClick={() => getTransactionReport(cell.folder_name)}
        data-toggle="modal"
        id="modalTransBtn"
        data-target="#modalTransaction"
      >
        <img src={folder} alt="" className="csvImg" />
        <span className="fileLabel">{cell.display_name}</span>
      </div>
    );
  };

  const GetActionPublish = (cell) => {
    return (
      <div>
        {cell.verified_flag ? (
          <div>
            <img src={published} alt="" className="publishedImg" />
            {cell.published_date}
          </div>
        ) : (
          <div>
            <img src={unpublished} alt="" className="unpublishedImg" />
            <button
              onClick={() =>
                publishYearWeekSelection(cell.display_name, cell.year_week)
              }
              className="publishBtn"
              data-toggle="modal"
              id="modalPublishConfirmationId"
              data-target="#modalPublishConfirmation"
            >
              Publish
            </button>
          </div>
        )}
      </div>
    );
  };

  const columns = [
    {
      field: "",
      // title: "Report List",
      render: (rowData) => GetActionFormat(rowData),
    },
    {
      field: "",
      // title: "Report List",
      render: (rowData) => GetActionPublish(rowData),
    },
  ];

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  //    const handleEvent = (event, picker) => {
  //      setFromDate(convert(picker.startDate._d));
  //      setToDate(convert(picker.endDate._d));
  //      setAgentListCall(true);
  //    };

  const dateClickCalenderFrom = (event) => {
    var dateVal = event.toString();
    var dateFrom = moment(new Date(dateVal.substr(0, 16)));
    setFromDate(moment(dateFrom.format("DD-MMM-YYYY")).format().slice(0, 10));
    setFromDateValue(event);
    setToDate("");
    setCalendarFrom(!showCalendarFrom);
  };

  const dateClickCalenderTo = (event) => {
    var dateVal = event.toString();
    var dateTo = moment(new Date(dateVal.substr(0, 16)));
    setToDate(moment(dateTo.format("DD-MMM-YYYY")).format().slice(0, 10));
    setToDateValue(event);
    setCalendarTo(!showCalendarTo);
    fectListFilter(
      fromDate,
      moment(dateTo.format("DD-MMM-YYYY")).format().slice(0, 10)
    );
    setLoad(true);
  };

  const showFrom = () => {
    setCalendarFrom(!showCalendarFrom);
    setCalendarTo(false);
  };

  const showTo = () => {
    if (fromDate !== "") {
      setCalendarTo(!showCalendarTo);
      setCalendarFrom(false);
    }
  };

  const clearFilterSelection = () => {
    fetchList();
    setFromDate("");
    setToDate("");
    setLoad(true);
  };

  return (
    <ContentWrapper>
      <span className="pageheading">Weekly Commission Reports</span>

      {load === true && <div className="loader" />}
      {load === true && (
        <div style={{ textAlign: "center" }}>
          Loading Weekly Commission Reports...
        </div>
      )}

      {load === false && (
        <div className="tableBody clearfix">
          <div
            className=""
            // data-toggle="modal"
            // id="modalTransBtn"
            // data-target="#modalTransaction"
          >
            <div className="calenderDivCommissionReport">
              <div className="calenderFilterByLabel">Filter by Date:</div>
              <button onClick={showFrom} className="calenderFromBtn">
                From {fromDate !== "" && <span>: {fromDate}</span>}
              </button>
              <Calendar
                onChange={dateClickCalenderFrom}
                value={fromDateValue}
                className={showCalendarFrom ? "" : "hide"}
                tileDisabled={({ date, view }) =>
                  (view === "month" && date.getDay() === 1) ||
                  date.getDay() === 2 ||
                  date.getDay() === 3 ||
                  date.getDay() === 4 ||
                  date.getDay() === 5 ||
                  date.getDay() === 6
                }
              />

              <br />

              <button onClick={showTo} className="calenderToBtn">
                To
                {toDate !== "" && <span>: {toDate}</span>}
              </button>
              <Calendar
                onChange={dateClickCalenderTo}
                minDate={fromDateValue}
                className={showCalendarTo ? "" : "hide"}
                tileDisabled={({ date, view }) =>
                  (view === "month" && date.getDay() === 1) ||
                  date.getDay() === 2 ||
                  date.getDay() === 3 ||
                  date.getDay() === 4 ||
                  date.getDay() === 5 ||
                  date.getDay() === 0
                }
              />
            </div>

            {toDate !== "" && (
              <span
                onClick={clearFilterSelection}
                className="clearSelectionLink"
              >
                Clear Selection
              </span>
            )}

            <MaterialTable
              // other props
              components={{
                Toolbar: (props) => (
                  <div style={{ backgroundColor: "#f6f6e6" }}>
                    <MTableToolbar {...props} />
                    {/* <DateRangePicker
                      onApply={handleEvent}
                      parentEl={"#reportDRPContainerWallet"}
                      
                      ranges={{
                        Today: [moment(), moment()],
                        Yesterday: [
                          moment().subtract(1, "days"),
                          moment().subtract(1, "days")
                        ],
                        "Last 7 Days": [moment().subtract(6, "days"), moment()],
                        "Last 30 Days": [
                          moment().subtract(29, "days"),
                          moment()
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month")
                        ],
                        "Last Month": [
                          moment()
                            .subtract(1, "month")
                            .startOf("month"),
                          moment()
                            .subtract(1, "month")
                            .endOf("month")
                        ],
                        "Last Year": [moment().subtract(366, "days"), moment()],
                        "Last 5 Years": [
                          moment().subtract(1826, "days"),
                          moment()
                        ]
                      }}
                      alwaysShowCalendars={true}
                    >
                      <button className="filterByDateButton dateFilterPosition">
                        <img
                          alt=""
                          src={buttonSymbol}
                          className="buttonSymbol"
                        />
                        Filter by Date
                      </button>
                    </DateRangePicker> */}
                  </div>
                ),
              }}
              columns={columns}
              data={agentList}
              options={{
                pageSize: 10,
                searchFieldAlignment: "left",
                searchFieldStyle: {
                  backgroundColor: "white",
                },
                search: false,
              }}
              title={false}
            />
          </div>
        </div>
      )}

      <div
        className="modal fade"
        id="modalTransaction"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered dialogWidthCSV">
          <div
            className="modal-content modal-content-bg"
            style={{ height: "700px" }}
          >
            <div className="modalHeading tableModalHeading">
              Payment Summary
            </div>
            <img
              src={close}
              alt=""
              className="modalCloseBtn"
              data-dismiss="modal"
              id="modalCloseBtn"
            />

            {transLoad === true && <div className="loader" />}
            {transLoad === true && (
              <div style={{ textAlign: "center" }}>
                Loading Payment Summary...
              </div>
            )}
            {load === false && (
              <div className="tableModalContentDiv tableModalContentDivCSV">
                {agentListCSV.map((item) => (
                  <div className="csvDiv">
                    <img src={csv} alt="" className="csvImg" />
                    <span>{item.file_name}</span>
                    <a href={item.url}>
                      <img src={download} alt="" className="csvDwnldImg" />
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalPublishConfirmation"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered dialogWidthCSV">
          <div
            className="modal-content modal-content-bg"
            style={{ height: "200px" }}
          >
            <div className="modalConfirmationHeading tableModalHeading">
              Are you sure you want to publish the folder, {publishDisplayName}?
            </div>

            <div className="btnConfirmationDiv">
              <button
                className="btnConfirmation"
                data-dismiss="modal"
                onClick={() => publishYearWeekClick(publishYearWeek)}
              >
                Yes
              </button>
              <button className="btnConfirmation" data-dismiss="modal">
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default CommissionReport;
