import React from "react";
import ContentWrapper from "../../Common/ContentWrapper";

const ChannelRules = () => {
  return (
    <ContentWrapper>
      <div className="comingSoon clearfix">Coming soon...</div>
      {/* <h2>ChannelRules Page</h2> */}
    </ContentWrapper>
  );
};

export default ChannelRules;
