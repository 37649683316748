import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import MaterialTable, { MTableToolbar } from "material-table";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import { useSelector, useDispatch } from "react-redux";
import {
  setChannels,
  setYTD,
  setYTDMonth,
  setYTDWeek,
} from "../../../../redux/actions/productActions";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import buttonSymbol from "../../../../assets/images/calender.png";
import close from "../../../../assets/images/close.png";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    textTransform: "none",
    // color: '#fff',
  },
  indicator: {
    backgroundColor: "#533278",
  },
  textColor: {
    color: "#533278",
  },
}));

const StyledRadio = withStyles({
  root: {
    color: "#533278",
    "&$checked": {
      color: "#533278",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const ContributionAllocationYTD = () => {
  const classes = useStyles();
  const now = new Date();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const tempToDate = `${now.getFullYear()}-${month}-${day}`;
  const [toDateCurrent, setToDateCurrent] = useState(tempToDate);

  const [loadMonthly, setLoadMonthly] = useState(false);
  const [loadDaily, setLoadDaily] = useState(false);
  const [datekey, setDatekey] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [transactionLoad, setTransactionLoad] = useState(false);
  const [weekList, setWeeklyList] = useState([]);
  const [monthlyList, setMonthlyList] = useState([]);
  const [dailyList, setDailyList] = useState(["null"]);
  const [dailyYTDTransList, setYTDTransList] = useState([]);
  const [transYTDdate, setTransYTDdate] = useState(null);
  const [channelList, setChannelList] = useState([]);
  const [agentListCall, setAgentListCall] = useState(true);
  const [agentListFilterCall, setAgentListFilterCall] = useState(true);
  const [selectedChannel, setSelectedChannel] = useState("All");
  const [fromDate, setFromDate] = useState("2011-06-09");
  const [toDate, setToDate] = useState(tempToDate);
  const [empId, setEmpId] = useState(true);
  const [empList, setEmpList] = useState([]);
  const [empClick, setEmpClick] = useState(false);
  const [empLoad, setEmpLoad] = useState(false);
  const [value, setValue] = useState(0);
  const [selectedValue, setSelectedValue] = React.useState("month");
  const [loadWeek, setLoadWeek] = useState(true);
  const [downloadLoad, setDownloadLoad] = useState(false);
  const pii = JSON.parse(localStorage.getItem("PII"));
  let username = localStorage.getItem(
    "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr.LastAuthUser"
  );
  let id_token = localStorage.getItem(
    "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr." +
      username +
      ".idToken"
  );
  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "ytd",
  };
  const csvExporter = new ExportToCsv(options);
  async function fetchList(selected_channel) {
    setLoadWeek(true);
    setSelectedChannel(selected_channel)
    const request = await axios.get(
      config.regulatorPayableYTDReportWeekly + selected_channel,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token,
        },
      }
    );
    if (dataList.channels.ytdWeek.length === 0) {
      dispatch(setYTDWeek(request.data));
    }
    setWeeklyList(request.data);
    setLoadWeek(false);
  }
  async function fetchYTDList(from, to, selected_channel) {
    setLoadDaily(true);
    setFromDate(from)
    setToDate(to)
    setSelectedChannel(selected_channel)
    const request = await axios.get(
      config.regulatorPayableYTDReport +
        "from_date=" +
        from +
        "&to_date=" +
        to +
        "&channel=" +
        selected_channel,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token,
        },
      }
    );
    if (dataList.channels.ytd.length === 0) {
      dispatch(setYTD(request.data));
    }
    setDailyList(request.data);
    setLoadDaily(false);
  }
  async function fetchMonthlyList(selected_channel) {
    setLoadMonthly(true);
    setSelectedChannel(selected_channel)
    const request = await axios.get(
      config.regulatorPayableYTDReportMonthly + selected_channel,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token,
        },
      }
    );
    // if (agentListCall === true) {

    setAgentListCall(false);

    // }
    if (dataList.channels.ytdMonth.length === 0) {
      dispatch(setYTDMonth(request.data));
    }
    setMonthlyList(request.data);
    setLoadMonthly(false);
  }

  async function fetchYTDTransList(date) {
    const request = await axios.get(
      config.regulatorPayableYTDTransReport + date,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token,
        },
      }
    );
    setYTDTransList(request.data);
    setTransactionLoad(false);

    // setTransYTDdate(null)
  }
  async function downloadfetchList() {
    setDownloadLoad(true);

    // const request = await axios.get(
    //   config.regulatorPayableYTDReportWeekly + selectedChannel,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "x-api-key": apikey.xapikey,
    //       token: id_token,
    //     },
    //   }
    // );

    let tempWeekData = [];
    const tempData = weekList;
        // const tempData = request.data[0].payload;

    for (let i = 0; i < tempData.length; i++) {
      tempWeekData.push({
        "Date": tempData[i].date,
        "Week Number": tempData[i].week_number,
        "Total": tempData[i].total,
        "Application Fee": tempData[i].application_fee,
        "Monthly Contributions": tempData[i].monthly_share,
        "Membership Fee": tempData[i].membership_fee,
        "CC Fee": tempData[i].cc_fee,
        "Additional Revenue": tempData[i].additional_revenue,
        "Administration Expenses": tempData[i].administration_expenses,
        "BMA Service Fees": tempData[i].bma_service_fees,
        "Commission Load": tempData[i].commission_load,
        "Fixed Expenses": tempData[i].fixed_expenses,
        "Sharewallet": tempData[i].sharewallet_balance,
      });
    }

    csvExporter.generateCsv(tempWeekData);
    // csvExporter.generateCsv(weekList);
    setDownloadLoad(false);
  }
  async function downloadfetchYTDList() {
    setDownloadLoad(true);

    const request = await axios.get(
      config.regulatorPayableYTDReportDaily +
        "from_date=2011-06-09" +
        "&to_date=" +
        toDateCurrent +
        "&channel=All",
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token,
        },
      }
    ); 
    let tempDailyData = [];
    // const tempData = dailyList;
        const tempData = request.data;
    //  for (let i = 0; i < tempData.length; i++) {
    //   tempDailyData.push({
    //     "Date": tempData[i].date,
    //     "Total": tempData[i].total,
    //     "Application Fee": tempData[i].application_fee,
    //     "Monthly Contributions": tempData[i].monthly_share,
    //     "Membership Fee": tempData[i].membership_fee,
    //     "CC Fee": tempData[i].cc_fee,
    //     "Additional Revenue": tempData[i].additional_revenue,
    //     "Administration Expenses": tempData[i].administration_expenses,
    //     "BMA Service Fees": tempData[i].bma_service_fees,
    //     "Commission Load": tempData[i].commission_load,
    //     "Fixed Expenses": tempData[i].fixed_expenses,
    //     "Sharewallet": tempData[i].sharewallet_balance,
    //   });
    // }

    csvExporter.generateCsv(tempData);
    setDownloadLoad(false);
  }
  async function downloadfetchMonthlyList(selected_channel) {
    setDownloadLoad(true);

    // const request = await axios.get(
    //   config.regulatorPayableYTDReportMonthly + selected_channel,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "x-api-key": apikey.xapikey,
    //       token: id_token,
    //     },
    //   }
    // );

    let tempMonthData = [];
    const tempData = monthlyList;
        // const tempData = request.data[0].payload;

    for (let i = 0; i < tempData.length; i++) {
      tempMonthData.push({
        "Month": tempData[i].month,
        "Year": tempData[i].year,
        "Total": tempData[i].total,
        "Application Fee": tempData[i].application_fee,
        "Monthly Contributions": tempData[i].monthly_share,
        "Membership Fee": tempData[i].membership_fee,
        "CC Fee": tempData[i].cc_fee,
        "Additional Revenue": tempData[i].additional_revenue,
        "Administration Expenses": tempData[i].administration_expenses,
        "BMA Service Fees": tempData[i].bma_service_fees,
        "Commission Load": tempData[i].commission_load,
        "Fixed Expenses": tempData[i].fixed_expenses,
        "Sharewallet": tempData[i].sharewallet_balance,
      });
    }

    csvExporter.generateCsv(tempMonthData);
    setDownloadLoad(false);
  }

  async function downloadfetchYTDTransList() {
    // const request = await axios.get(
    //   config.regulatorPayableYTDTransReport + transYTDdate,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "x-api-key": apikey.xapikey,
    //       token: id_token,
    //     },
    //   }
    // );

    let tempTransactionData = [];
    const tempData = dailyYTDTransList;
        // const tempData = request.data[0].payload;

    for (let i = 0; i < tempData.length; i++) {
      tempTransactionData.push({
        "Transaction ID": tempData[i].source_transaction_id,
        "User ID": tempData[i].uuid,
        "Member First Name": tempData[i].first_name,
        "Member Last Name": tempData[i].last_name,
        "Member Status": tempData[i].member_status,
        "Benefit Begin": tempData[i].benefit_begin,
        "Payment Number": tempData[i].payment_number,
        "Agent ID": tempData[i].agent_id,
        "Agent First Name": tempData[i].agent_firstname,
        "Agent Last Nam": tempData[i].agent_lastname,
        "Affiliation ID": tempData[i].affiliation_id,
        "Association ID": tempData[i].association_id,
        "Program Name": tempData[i].program_name,
        "Monthly Share": tempData[i].monthly_share,
        "Membership Fee": tempData[i].membership_fee,
        "CC Fee": tempData[i].cc_fee,
        "Additional Revenu": tempData[i].additional_revenue,
        "Sharewallet": tempData[i].sharewallet_balance,
        "Administration Expenses": tempData[i].administration_expenses,
        "BMA Service Fees": tempData[i].bma_service_fees,
        "Commission Load": tempData[i].commission_load,
        "Fixed Expenses": tempData[i].fixed_expenses,
        "Application Fee": tempData[i].fixed_expenses,
      });
    }


    csvExporter.generateCsv(tempTransactionData);
  }
  async function fetchChannelList() {
    const request = await axios.get(config.channelList, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apikey.xapikey,
        token: id_token,
      },
    });
    dispatch(setChannels(request.data));
    setChannelList(request.data);
  }
  async function fetchEmp() {
    setEmpLoad(true);
    const request = await axios.get(config.empDetails + empId, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apikey.xapikey,
        token: id_token,
      },
    });
    setEmpLoad(false);
    if (request.data !== null) {
      setEmpList(request.data);
    }
  }
  const handleChange = (event, newValue) => {
    if (event.target.innerText == "BY WEEK") {
      setSelectedValue("week");
      fetchList(selectedChannel);
    } else if (event.target.innerText == "BY DAY") {
      setSelectedValue("day");
      fetchYTDList(fromDate, toDate, selectedChannel);
    } else {
      setSelectedValue("month");
    }
    setValue(newValue);
  };
  useEffect(() => {
    if (empClick === true) {
      setEmpClick(false);
      setEmpLoad(true);

      fetchEmp();
    }
    setChannelList(dataList.channels.channels);

    if (agentListCall === true || agentListFilterCall === true) {
      setAgentListCall(false);
      setAgentListFilterCall(false);
      if (dataList.channels.ytd.length > 0 && dailyList[0] === "null") {
        setDailyList(dataList.channels.ytd);
      } else {
        setLoadDaily(true);
        setAgentListCall(false);
        setAgentListFilterCall(false);
      }
    }

    if (agentListCall === true || agentListFilterCall === true) {
      setAgentListCall(false);
      setAgentListFilterCall(false);

      if (dataList.channels.ytdMonth.length > 0 && monthlyList[0] === "null") {
        // setMonthlyList(dataList.channels.ytdMonth);
        setWeeklyList(dataList.channels.ytdWeek);
      } else {
        setLoadMonthly(true);

        setAgentListCall(false);

        fetchMonthlyList(selectedChannel);
      }
    }

    // if (transYTDdate !== null) {
    //   setAgentListCall(false);
    //   setTransactionLoad(true);

    //   fetchYTDTransList(transYTDdate);
    // }

    if (channelList.length === 0) {
      if (dataList.channels.channels.length === 0) {
        fetchChannelList();
      }
    }
  }, [
    agentListCall,
    agentListFilterCall,
    weekList,
    monthlyList,
    dailyList,
    channelList,
    empClick,
    transYTDdate,
  ]);

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleEvent = (event, picker) => {
    let from = convert(picker.startDate._d);
    let to = convert(picker.endDate._d);
    setFromDate(convert(picker.startDate._d));
    setToDate(convert(picker.endDate._d));
    fetchYTDList(from, to, selectedChannel);
  };

  const channelSelect = (e) => {
    if (e.target.id == "week") {
      fetchList(e.target.value);
    } else if (e.target.id == "day") {
      fetchYTDList(fromDate, toDate, e.target.value);
    } else {
      fetchMonthlyList(e.target.value);
    }
    e.preventDefault();
    // setAgentListCall(true);
    setSelectedChannel(e.target.value);
  };

  const empIdClick = (id) => {
    setEmpList([]);
    setEmpClick(true);
    setEmpId(id);
    fetchEmp();
    document.getElementById("modalEmpBtn").click();
  };

  const GetActionEmpIdFormat = (row) => {
    return (
      <div>
        <label
          className="empName"
          size="sm"
          onClick={() => empIdClick(row.uuid)}
        >
          {row.uuid}
        </label>
      </div>
    );
  };
  const handleClick = (event, row) => {
    setDatekey(row.date);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const GetActionFormat = (row) => {
    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          // onClick={handleClick}
          onClick={(e) => handleClick(e, row)}
        >
          <MoreVertIcon />
        </IconButton>
      </div>
    );
  };

  const transaction = () => {
    setYTDTransList([]);
    setTransactionLoad(true);
    setAnchorEl(null);
    setTransYTDdate(datekey);
    fetchYTDTransList(datekey);
    document.getElementById("modalTransBtn").click();
  };
  const columnsDaily = [
    {
      title: "Date",
      field: "date",
    },
    {
      title: "Total   ",
      field: "total",
    },
    {
      title: "Application Fee",
      field: "application_fee",
    },
    {
      title: "Monthly Contributions ",
      field: "monthly_share",
      cellStyle: {
        width: "200px",
      },
    },
    {
      title: "Membership Fee",
      field: "membership_fee",
    },
    {
      title: "CC Fee",
      field: "cc_fee",
    },
    {
      field: "",
      title: "Action",
      render: (cell) => GetActionFormat(cell),
    },
  ];

  const rowExpandColumns = [
    {
      title: "Transaction ID",
      field: "source_transaction_id",
    },
    {
      title: "User ID",
      field: "uuid",
      render: (cell) => GetActionEmpIdFormat(cell),
    },
    {
      title: "Member First Name",
      field: "first_name",
    },
    {
      title: "Member Last Name",
      field: "last_name",
    },
    {
      title: "Member Status",
      field: "member_status",
    },
    {
      title: "Benefit Begin",
      field: "benefit_begin",
    },

    {
      title: "Payment Number",
      field: "payment_number",
    },
    {
      title: "Agent ID",
      field: "agent_id",
    },
    {
      title: "Agent First Name",
      field: "agent_firstname",
    },
    {
      title: "Agent Last Name",
      field: "agent_lastname",
    },
    {
      title: "Affiliation Id",
      field: "affiliation_id",
    },
    {
      title: "Association Id",
      field: "association_id",
    },
    {
      title: "Program Name",
      field: "program_name",
    },
  ];

  const columnsMonth = [
    {
      title: "Month",
      field: "month",
    },
    {
      title: "Year",
      field: "year",
    },
    {
      title: "Total   ",
      field: "total",
    },
    {
      title: "Application Fee",
      field: "application_fee",
    },
    {
      title: "Monthly Contributions ",
      field: "monthly_share",
      cellStyle: {
        width: "200px",
      },
    },
    {
      title: "Membership Fee",
      field: "membership_fee",
    },
    {
      title: "CC Fee",
      field: "cc_fee",
    },
  ];

  const columnsWeek = [
    {
      field: "date",
      title: "Date",
    },
    {
      field: "week_number",
      title: "Week Number",
    },
    {
      title: "Total   ",
      field: "total",
    },
    {
      title: "Application Fee",
      field: "application_fee",
    },
    {
      title: "Monthly Contributions ",
      field: "monthly_share",
      cellStyle: {
        width: "200px",
      },
    },
    {
      title: "Membership Fee",
      field: "membership_fee",
    },
    {
      title: "CC Fee",
      field: "cc_fee",
    },
  ];

  return (
    <ContentWrapper>
      {/* <span className="pageheading">Contribution Allocation YTD</span> */}
      {/* <div className="comingSoon clearfix">Coming soon...</div> */}
      <div className="tableBody clearfix">
        <button
          data-toggle="modal"
          id="modalEmpBtn"
          data-target="#modalEmp"
          style={{ display: "none" }}
        >
          Emp
        </button>

        <div
          className="modal fade"
          id="modalEmp"
          role="dialog"
          aria-labelledby="exampleModalLabel1"
          aria-hidden="true"
          style={{ zIndex: "2000" }}
        >
          <div className="modal-dialog modal-dialog-centered dialogEmpWidth">
            <div className="modal-content">
              <div className="modalHeading">Member Info</div>

              <div className="modalContentDiv">
                {empLoad === true && <div className="loader" />}
                {empLoad === true && (
                  <div style={{ textAlign: "center" }}>Loading Member Info</div>
                )}
                {empLoad === false && (
                  <div className="empDiv">
                    <div className="empDataDiv">
                      <span className="empQus">Name :</span>{" "}
                      {pii == true ? (
                        <span
                          style={{
                            backgroundColor: "black",
                            color: "black",
                            cursor: "not-allowed",
                          }}
                        >
                          {empList.first_name}&nbsp; {empList.last_name}
                        </span>
                      ) : (
                        <span>
                          {empList.first_name}&nbsp; {empList.last_name}
                        </span>
                      )}
                    </div>
                    <div className="empDataDiv">
                      <span className="empQus">User Id :</span>{" "}
                      <span>{empList.uuid}</span>
                    </div>
                    <div className="empDataDiv">
                      <span className="empQus">Plan Id :</span>{" "}
                      <span>{empList.plan_id}</span>
                    </div>
                    <div className="empDataDiv">
                      <span className="empQus">State Code :</span>{" "}
                      <span>{empList.state_code}</span>
                    </div>
                    <div className="empDataDiv">
                      <span className="empQus">Benefit Begin :</span>{" "}
                      <span>{empList.benefit_begin}</span>
                    </div>
                    <div className="empDataDiv">
                      <span className="empQus">Benefit End :</span>{" "}
                      <span>{empList.benefit_end}</span>
                    </div>
                    <div className="empDataDiv">
                      <span className="empQus">Paid Through Date:</span>{" "}
                      <span>{empList.paid_through_date}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="modalFooter">
                <button
                  data-dismiss="modal"
                  id="modalCloseBtn2"
                  style={{ display: "block" }}
                  className="modalFooterDoneBtn"
                >
                  Done
                </button>
                <button
                  data-dismiss="modal"
                  id="modalCloseBtn1"
                  style={{ display: "block" }}
                  className="modalFooterCloseBtn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          // onClick={handleClick}
          style={{ display: "none" }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => transaction()}>
            <label>Transaction</label>
          </MenuItem>
        </Menu>
        <button
          // className={styles.addNewBtn}
          data-toggle="modal"
          id="modalTransBtn"
          data-target="#modalTransaction"
          style={{ display: "none" }}
        >
          Transaction
        </button>
        <div
          className="modal fade"
          id="modalTransaction"
          role="dialog"
          aria-labelledby="exampleModalLabel1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered dialogWidth">
            <div className="modal-content-bg modal-content">
              <div className="modalHeading tableModalHeading">
                Transaction Data
              </div>
              <img
                src={close}
                alt=""
                className="modalCloseBtn"
                data-dismiss="modal"
                id="modalCloseBtn"
              />

              <div className="tableModalContentDiv">
                {transactionLoad === true && <div className="loader" />}
                {transactionLoad === true && (
                  <div style={{ textAlign: "center" }}>
                    Loading Transaction Data
                  </div>
                )}
                {transactionLoad === false && (
                  <div>
                    <MaterialTable
                      // other props
                      components={{
                        Toolbar: (props) => (
                          <div style={{ backgroundColor: "#f6f6e6" }}>
                            <MTableToolbar {...props} />
                          </div>
                        ),
                      }}
                      columns={rowExpandColumns}
                      data={dailyYTDTransList}
                      options={{
                        pageSize: 10,
                        searchFieldAlignment: "left",
                        searchFieldStyle: {
                          backgroundColor: "white",
                        },
                      }}
                      detailPanel={(rowData) => {
                        return (
                          <div className="detailPanel">
                            <table className="detailPanelTable">
                              <tr className="detailPaneltr">
                                <th className="detailPanelth" scope="col">
                                  Monthly Share
                                </th>
                                <th className="detailPanelth" scope="col">
                                  Membership Fee
                                </th>
                                <th className="detailPanelth" scope="col">
                                  CC Fee
                                </th>
                                <th className="detailPanelth" scope="col">
                                  Additional Revenue
                                </th>
                                <th className="detailPanelth" scope="col">
                                  Sharewallet
                                </th>
                              </tr>
                              <tr>
                                <td className="detailPaneltd">
                                  {rowData.monthly_share}
                                </td>
                                <td className="detailPaneltd">
                                  {rowData.membership_fee}
                                </td>
                                <td className="detailPaneltd">
                                  {rowData.cc_fee}
                                </td>
                                <td className="detailPaneltd">
                                  {rowData.additional_revenue}
                                </td>
                                <td className="detailPaneltd">
                                  {rowData.sharewallet_balance}
                                </td>
                              </tr>
                            </table>
                            <table className="detailPanelTable">
                              <tr className="detailPaneltr">
                                <th className="detailPanelth" scope="col">
                                  Administration Expenses
                                </th>
                                <th className="detailPanelth" scope="col">
                                  BMA Service Fees
                                </th>
                                <th className="detailPanelth" scope="col">
                                  Commission Load
                                </th>
                                <th className="detailPanelth" scope="col">
                                  Fixed Expenses
                                </th>
                                <th className="detailPanelth" scope="col">
                                  Application Fee
                                </th>
                              </tr>
                              <tr>
                                <td className="detailPaneltd">
                                  {rowData.administration_expenses}
                                </td>
                                <td className="detailPaneltd">
                                  {rowData.bma_service_fees}
                                </td>
                                <td className="detailPaneltd">
                                  {rowData.commission_load}
                                </td>
                                <td className="detailPaneltd">
                                  {rowData.fixed_expenses}
                                </td>
                                <td className="detailPaneltd">
                                  {rowData.application_fee}
                                </td>
                              </tr>
                            </table>
                          </div>
                        );
                      }}
                      title={false}
                      actions={[
                        {
                          icon: "save_alt",
                          tooltip: "Export Data",
                          isFreeAction: true,
                          iconProps: {
                            style: {
                              backgroundColor: "#eb5757",
                              width: "35px",
                              height: "35px",
                              borderRadius: "20px",
                              paddingTop: "6px",
                              color: "white",
                            },
                          },
                          onClick: () => downloadfetchYTDTransList(),
                        },
                        // {
                        //   icon: "refresh",
                        //   tooltip: "Refresh Data",
                        //   isFreeAction: true,
                        //   iconProps: {
                        //     style: {
                        //       backgroundColor: "#41b5c2",
                        //       width: "35px",
                        //       height: "35px",
                        //       borderRadius: "20px",
                        //       paddingTop: "6px",
                        //       color: "white",
                        //     },
                        //   },
                        //   onClick: () => fetchYTDTransList(transYTDdate),
                        // },
                      ]}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{
              indicator: classes.indicator,
            }}
            aria-label="simple tabs example"
          >
            <Tab
              label={
                <span style={{ color: "#533278" }}>
                  <StyledRadio
                    checked={selectedValue === "month"}
                    value="month"
                    name="radio-button"
                    disableRipple
                    disabled
                    inputProps={{ "aria-label": "MONTH" }}
                  />
                  BY MONTH
                </span>
              }
            />
            <Tab
              label={
                <span style={{ color: "#533278" }}>
                  <StyledRadio
                    disableRipple
                    checked={selectedValue === "week"}
                    name="radio-button"
                    disabled
                    inputProps={{ "aria-label": "WEEK" }}
                  />
                  BY WEEK
                </span>
              }
            />
            <Tab
              label={
                <span style={{ color: "#533278" }}>
                  <StyledRadio
                    disableRipple
                    disabled
                    checked={selectedValue === "day"}
                    name="radio-button"
                    inputProps={{ "aria-label": "DAY" }}
                  />
                  BY DAY
                </span>
              }
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {loadMonthly === true && <div className="loader" />}
          {loadMonthly === true && (
            <div style={{ textAlign: "center" }}>Loading Monthly List</div>
          )}

          {loadMonthly === false && (
            <div className="tableInnerDiv">
              <MaterialTable
                // other props
                components={{
                  Toolbar: (props) => (
                    <div style={{ backgroundColor: "#f6f6e6" }}>
                      <MTableToolbar {...props} />

                      <select
                        id="month"
                        onChange={channelSelect}
                        className="filterByDateButton channelFilterPosition searchInput"
                        style={{ textAlign: "center" }}
                        value={selectedChannel}
                      >
                        <option value="All"> Select Channel </option>
                        {channelList.map((item,index) => (
                          <option key={index} value={item.channel_id}>
                            {item.channel_name}
                          </option>
                        ))}
                      </select>
                      {downloadLoad === true && <div className="loaderDwnld" />}
                      {downloadLoad === true && (
                        <div
                          style={{
                            textAlign: "center",
                            position: "absolute",
                            right: "50px",
                          }}
                        >
                          downloading
                        </div>
                      )}
                      <div style={{ display: "inline-block" }}></div>
                    </div>
                  ),
                }}
                columns={columnsMonth}
                data={monthlyList}
                detailPanel={(rowData) => {
                  return (
                    <div className="detailPanel">
                      <table className="detailPanelTable">
                        <tr className="detailPaneltr">
                          <th className="detailPanelth" scope="col">
                            Additional Revenue
                          </th>
                          {/* <th className="detailPanelth" scope="col">
                            Additional Sharepower Backup
                          </th> */}
                          <th className="detailPanelth" scope="col">
                            Administration Expenses
                          </th>
                          <th className="detailPanelth" scope="col">
                            BMA Service Fees
                          </th>
                          {/* <th className="detailPanelth" scope="col">
                            Commission Bridge
                          </th> */}
                          <th className="detailPanelth" scope="col">
                            Commission Load
                          </th>
                          <th className="detailPanelth" scope="col">
                            Fixed Expenses
                          </th>
                          <th className="detailPanelth" scope="col">
                            Sharewallet
                          </th>
                        </tr>
                        <tr>
                          <td className="detailPaneltd">
                            {rowData.additional_revenue}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.administration_expenses}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.bma_service_fees}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.commission_load}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.fixed_expenses}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.sharewallet_balance}
                          </td>
                        </tr>
                      </table>
                    </div>
                  );
                }}
                options={{
                  pageSize: 10,
                  searchFieldAlignment: "left",
                  searchFieldStyle: {
                    backgroundColor: "white",
                  },
                }}
                title={false}
                actions={[
                  {
                    icon: "save_alt",
                    tooltip: "Export Data",
                    isFreeAction: true,
                    iconProps: {
                      style: {
                        backgroundColor: "#eb5757",
                        width: "35px",
                        height: "35px",
                        borderRadius: "20px",
                        paddingTop: "6px",
                        color: "white",
                      },
                    },
                    onClick: () => downloadfetchMonthlyList(selectedChannel),
                  },
                  {
                    icon: "refresh",
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    iconProps: {
                      style: {
                        backgroundColor: "#41b5c2",
                        width: "35px",
                        height: "35px",
                        borderRadius: "20px",
                        paddingTop: "6px",
                        color: "white",
                      },
                    },
                    onClick: () => fetchMonthlyList("All"),
                  },
                ]}
              />
            </div>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {loadWeek === true && <div className="loader" />}
          {loadWeek === true && (
            <div style={{ textAlign: "center" }}>Loading Weekly List</div>
          )}

          {loadWeek === false && (
            <div className="tableInnerDiv">
              <MaterialTable
                // other props
                components={{
                  Toolbar: (props) => (
                    <div style={{ backgroundColor: "#f6f6e6" }}>
                      <MTableToolbar {...props} />
                      <select
                        id="week"
                        onChange={channelSelect}
                        className="filterByDateButton channelFilterPosition searchInput"
                        style={{ textAlign: "center" }}
                        value={selectedChannel}
                      >
                        <option value="All"> Select Channel </option>
                        {channelList.map((item,index) => (
                          <option key={index} value={item.channel_id}>
                            {item.channel_name}
                          </option>
                        ))}
                      </select>
                      {downloadLoad === true && <div className="loaderDwnld" />}
                      {downloadLoad === true && (
                        <div
                          style={{
                            textAlign: "center",
                            position: "absolute",
                            right: "50px",
                          }}
                        >
                          downloading
                        </div>
                      )}
                      <div style={{ display: "inline-block" }}></div>
                    </div>
                  ),
                }}
                columns={columnsWeek}
                data={weekList}
                detailPanel={(rowData) => {
                  return (
                    <div className="detailPanel">
                      <table className="detailPanelTable">
                        <tr className="detailPaneltr">
                          <th className="detailPanelth" scope="col">
                            Additional Revenue
                          </th>
                          <th className="detailPanelth" scope="col">
                            Administration Expenses
                          </th>
                          <th className="detailPanelth" scope="col">
                            BMA Service Fees
                          </th>
                          <th className="detailPanelth" scope="col">
                            Commission Load
                          </th>
                          <th className="detailPanelth" scope="col">
                            Fixed Expenses
                          </th>
                          <th className="detailPanelth" scope="col">
                            Sharewallet
                          </th>
                        </tr>
                        <tr>
                          <td className="detailPaneltd">
                            {rowData.additional_revenue}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.administration_expenses}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.bma_service_fees}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.commission_load}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.fixed_expenses}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.sharewallet_balance}
                          </td>
                        </tr>
                      </table>
                    </div>
                  );
                }}
                options={{
                  pageSize: 10,
                  searchFieldAlignment: "left",
                  searchFieldStyle: {
                    backgroundColor: "white",
                  },
                }}
                title={false}
                actions={[
                  {
                    icon: "save_alt",
                    tooltip: "Export Data",
                    isFreeAction: true,
                    iconProps: {
                      style: {
                        backgroundColor: "#eb5757",
                        width: "35px",
                        height: "35px",
                        borderRadius: "20px",
                        paddingTop: "6px",
                        color: "white",
                      },
                    },
                    onClick: () => downloadfetchList(),
                  },
                  {
                    icon: "refresh",
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    iconProps: {
                      style: {
                        backgroundColor: "#41b5c2",
                        width: "35px",
                        height: "35px",
                        borderRadius: "20px",
                        paddingTop: "6px",
                        color: "white",
                      },
                    },
                    onClick: () => fetchList("All"),
                  },
                ]}
              />
            </div>
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {loadDaily === true && <div className="loader" />}
          {loadDaily === true && (
            <div style={{ textAlign: "center" }}>Loading Daily List</div>
          )}

          {loadDaily === false && (
            <div className="tableInnerDiv">
              <MaterialTable
                // other props
                components={{
                  Toolbar: (props) => (
                    <div style={{ backgroundColor: "#f6f6e6" }}>
                      <MTableToolbar {...props} />
                      <select
                        id="day"
                        onChange={channelSelect}
                        className="filterByDateButton channelFilterPosition searchInput"
                        style={{ textAlign: "center" }}
                        value={selectedChannel}
                      >
                        <option value="All"> Select Channel </option>
                        {channelList.map((item,index) => (
                          <option key={index} value={item.channel_id}>
                            {item.channel_name}
                          </option>
                        ))}
                      </select>
                      {downloadLoad === true && <div className="loaderDwnld" />}
                      {downloadLoad === true && (
                        <div
                          style={{
                            textAlign: "center",
                            position: "absolute",
                            right: "50px",
                          }}
                        >
                          downloading
                        </div>
                      )}
                      <div style={{ display: "inline-block" }}></div>

                      <DateRangePicker
                        onApply={handleEvent}
                        parentEl={"#reportDRPContainerWallet"}
                        ranges={{
                          Today: [moment(), moment()],
                          Yesterday: [
                            moment().subtract(1, "days"),
                            moment().subtract(1, "days"),
                          ],
                          "Last 7 Days": [
                            moment().subtract(6, "days"),
                            moment(),
                          ],
                          "Last 30 Days": [
                            moment().subtract(29, "days"),
                            moment(),
                          ],
                          "This Month": [
                            moment().startOf("month"),
                            moment().endOf("month"),
                          ],
                          "Last Month": [
                            moment().subtract(1, "month").startOf("month"),
                            moment().subtract(1, "month").endOf("month"),
                          ],
                          "Last Year": [
                            moment().subtract(366, "days"),
                            moment(),
                          ],
                          "Last 5 Years": [
                            moment().subtract(1826, "days"),
                            moment(),
                          ],
                        }}
                        alwaysShowCalendars={true}
                      >
                        <button className="filterByDateButton dateFilterPosition">
                          <img
                            alt=""
                            src={buttonSymbol}
                            className="buttonSymbol"
                          />
                          {fromDate === "2011-06-09" ? (
                        <div>
                          Filter by Date
                        </div>
                      ) : (
                        <div>
                          {fromDate} - {toDate}
                        </div>
                      )}
                        </button>
                      </DateRangePicker>
                    </div>
                  ),
                }}
                columns={columnsDaily}
                data={dailyList}
                detailPanel={(rowData) => {
                  return (
                    <div className="detailPanel">
                      <table className="detailPanelTable">
                        <tr className="detailPaneltr">
                          <th className="detailPanelth" scope="col">
                            Additional Revenue
                          </th>
                          <th className="detailPanelth" scope="col">
                            Administration Expenses
                          </th>
                          <th className="detailPanelth" scope="col">
                            BMA Service Fees
                          </th>
                          <th className="detailPanelth" scope="col">
                            Commission Load
                          </th>
                          <th className="detailPanelth" scope="col">
                            Fixed Expenses
                          </th>
                          <th className="detailPanelth" scope="col">
                            Sharewallet
                          </th>
                        </tr>
                        <tr>
                          <td className="detailPaneltd">
                            {rowData.additional_revenue}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.administration_expenses}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.bma_service_fees}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.commission_load}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.fixed_expenses}
                          </td>
                          <td className="detailPaneltd">
                            {rowData.sharewallet_balance}
                          </td>
                        </tr>
                      </table>
                    </div>
                  );
                }}
                options={{
                  pageSize: 10,
                  searchFieldAlignment: "left",
                  searchFieldStyle: {
                    backgroundColor: "white",
                  },
                }}
                title={false}
                actions={[
                  {
                    icon: "save_alt",
                    tooltip: "Export Data",
                    isFreeAction: true,
                    iconProps: {
                      style: {
                        backgroundColor: "#eb5757",
                        width: "35px",
                        height: "35px",
                        borderRadius: "20px",
                        paddingTop: "6px",
                        color: "white",
                      },
                    },
                    onClick: () => downloadfetchYTDList(selectedChannel),
                  },
                  {
                    icon: "refresh",
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    iconProps: {
                      style: {
                        backgroundColor: "#41b5c2",
                        width: "35px",
                        height: "35px",
                        borderRadius: "20px",
                        paddingTop: "6px",
                        color: "white",
                      },
                    },
                    onClick: () => fetchYTDList("2011-06-09", toDateCurrent, "All"),
                  },
                ]}
              />
            </div>
          )}
        </TabPanel>
      </div>
    </ContentWrapper>
  );
};

export default ContributionAllocationYTD;
