import React from "react";
import ContentWrapper from "../../Common/ContentWrapper";

const RuleEditor = () => {
  return (
    <ContentWrapper>
      <div className="comingSoon clearfix">Coming soon...</div>
      {/* <iframe
        id="ruleEditor"
        src="https://dev.rule-editor.carynhealth.com"
        style={{ width: "100%", height: "85vh" }}
      ></iframe> */}
    </ContentWrapper>
  );
};

export default RuleEditor;
