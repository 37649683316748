import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import { useSelector, useDispatch } from "react-redux";
import { setChannels, setSharewalletBalance } from '../../../../redux/actions/productActions';
import MaterialTable ,{ MTableToolbar }from 'material-table';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from 'moment';
import { ExportToCsv } from "export-to-csv";
import buttonSymbol from "../../../../assets/images/calender.png"


const ShareWalletBalance = () => {
  const now = new Date();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now
    .getDate()
    .toString()
    .padStart(2, "0");
  const tempToDate = `${now.getFullYear()}-${month}-${day}`;
  const [toDateCurrent, setToDateCurrent] = useState(tempToDate);

  const [load, setLoad] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [agentListCall, setAgentListCall] = useState(true);
  const [selectedChannel, setSelectedChannel] = useState("All");
  const [fromDate, setFromDate] = useState("2011-06-09");
  const [toDate, setToDate] = useState(tempToDate);
  const [downloadLoad, setDownloadLoad] = useState(false);

  let username = localStorage.getItem(
    "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr.LastAuthUser"
  );
  let id_token = localStorage.getItem(
    "CognitoIdentityServiceProvider.1d1jtlojlgmttsrt634uns59mr." +
      username +
      ".idToken"
  );

  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "Sharewallet"
  };
  const csvExporter = new ExportToCsv(options);
  async function fetchList(fromDate, toDate, selectedChannel) {
    setFromDate(fromDate)
    setToDate(toDate)
    setSelectedChannel(selectedChannel)
    setLoad(true);
    const request = await axios.get(
      config.regulatorPayableShareWalletBalance +
      "from_date=" +
      fromDate +
      "&to_date=" +
      toDate +
      "&channel=" +
      selectedChannel,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    if (dataList.channels.sharewallet.length === 0) {
      dispatch(setSharewalletBalance(request.data));
    }
    setAgentList(request.data);
     setLoad(false);
  }
  async function downloadList() {
    setDownloadLoad(true);
    // const request = await axios.get(
    //   config.regulatorPayableShareWalletBalance +
    //   "from_date=" +
    //   fromDate +
    //   "&to_date=" +
    //   toDate +
    //   "&channel=" +
    //   selectedChannel,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "x-api-key": apikey.xapikey,
    //       token: id_token
    //     }
    //   }
    // );

    let sharewalletData = [];
    const tempData = agentList;

    for (let i = 0; i < tempData.length; i++) {
      sharewalletData.push({
        "Date": tempData[i].date,
        "Sharewallet": tempData[i].sharewallet_balance
      });
    }
  
    csvExporter.generateCsv(sharewalletData);
    setDownloadLoad(false);
  }
      async function fetchChannelList() {
          const request = await axios.get(config.channelList, {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apikey.xapikey,
              token: id_token
            }
          });
          dispatch(setChannels(request.data));
          setChannelList(request.data);
        }
  useEffect(() => {
    if (agentListCall === true) {
      setAgentListCall(false);
      if(dataList.channels.sharewallet.length > 0 && (agentList[0] === 'null' || agentList[0] === undefined)) {
        setAgentList(dataList.channels.sharewallet);
      } else {
        setLoad(true);
        setAgentListCall(false);

        fetchList(fromDate, toDate, selectedChannel);
      }

    }
    setChannelList(dataList.channels.channels);

    if (channelList.length === 0) {
      if (dataList.channels.channels.length === 0) {
    
        fetchChannelList();
      }
    }
  }, [
    agentListCall,
    agentList,
    channelList
  ]);


  const convert = str => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleEvent = (event, picker) => {
    setFromDate(convert(picker.startDate._d));
    setToDate(convert(picker.endDate._d));
    setAgentListCall(true);
  };

  const channelSelect = e => {
    e.preventDefault();
    setAgentListCall(true);
    setSelectedChannel(e.target.value);
  };

 
  const columns = [
    {
      field: "date",
      title: "Date"
    },
    {
      field: "sharewallet_balance",
      title: "Sharewallet"
    }
  ];



  return (
    <ContentWrapper>
      {/* <div className="comingSoon clearfix">Coming soon...</div> */}
      <span className="pageheading">ShareWallet Balance</span>

      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ textAlign: "center" }}>Loading List</div>
        )}

        {load === false && (
          <MaterialTable
            // other props
            components={{
              Toolbar: (props) => (
                <div style={{ backgroundColor: "#f6f6e6" }}>
                  <MTableToolbar {...props} />
                  <select
                    onChange={channelSelect}
                    className="filterByDateButton channelFilterPosition searchInput"
                    style={{ textAlign: "center" }}
                    value={selectedChannel}
                  >
                    <option value="All"> Select Channel </option>
                    {channelList.map((item,index) => (
                      <option key={index} value={item.channel_id}>
                        {item.channel_name}
                      </option>
                    ))}
                  </select>
                  {downloadLoad === true && <div className="loaderDwnld" />}
                  {downloadLoad === true && (
                    <div
                      style={{
                        textAlign: "center",
                        position: "absolute",
                        right: "50px"
                      }}
                    >
                      downloading
                    </div>
                  )}
                  <DateRangePicker
                    onApply={handleEvent}
                    parentEl={"#reportDRPContainerWallet"}
                    ranges={{
                      Today: [moment(), moment()],
                      Yesterday: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days")
                      ],
                      "Last 7 Days": [moment().subtract(6, "days"), moment()],
                      "Last 30 Days": [moment().subtract(29, "days"), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month")
                      ],
                      "Last Month": [
                        moment()
                          .subtract(1, "month")
                          .startOf("month"),
                        moment()
                          .subtract(1, "month")
                          .endOf("month")
                      ],
                      "Last Year": [moment().subtract(366, "days"), moment()],
                      "Last 5 Years": [
                        moment().subtract(1826, "days"),
                        moment()
                      ]
                    }}
                    alwaysShowCalendars={true}
                  >
                    <button className="filterByDateButton dateFilterPosition">
                      <img
                        alt=""
                        src={buttonSymbol}
                        className="buttonSymbol"
                      />
                      {fromDate === "2011-06-09" ? (
                        <div>
                          Filter by Date
                        </div>
                      ) : (
                        <div>
                          {fromDate} - {toDate}
                        </div>
                      )}
                      
                    </button>
                  </DateRangePicker>
                </div>
              )
            }}
            columns={columns}
            data={agentList}
            options={{
              pageSize: 10,
              searchFieldAlignment: "left",
              searchFieldStyle: {
                backgroundColor: "white"
              }
            }}
            title=""
            actions={[
              {
                icon: "save_alt",
                tooltip: "Export Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#eb5757",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                    color: "#ffffff"
                  }
                },
                onClick: () => downloadList()
              },
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#41b5c2",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                    color: "#ffffff"
                  }
                },
                onClick: () => fetchList("2011-06-09", toDateCurrent, "All")
              }
            ]}
          />
        )}
      </div>
    </ContentWrapper>
  );
};

export default ShareWalletBalance;

